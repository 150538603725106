import { template as template_ee630644843a4e3390fe71c3f8e0eacc } from "@ember/template-compiler";
const FKLabel = template_ee630644843a4e3390fe71c3f8e0eacc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
