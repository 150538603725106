import { template as template_a0b20099e081400c93627d853fb4b4ee } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_a0b20099e081400c93627d853fb4b4ee(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
