import { template as template_367bdfee6d33421e8535c65f702c1acf } from "@ember/template-compiler";
const WelcomeHeader = template_367bdfee6d33421e8535c65f702c1acf(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
