import { template as template_e70f82977ef943809bc73814709e8d92 } from "@ember/template-compiler";
import AnonymousSections from "./anonymous/sections";
import UserSections from "./user/sections";
const SidebarSections = template_e70f82977ef943809bc73814709e8d92(`
  {{#if @currentUser}}
    <UserSections
      @collapsableSections={{@collapsableSections}}
      @panel={{@panel}}
      @hideApiSections={{@hideApiSections}}
    />
  {{else}}
    <AnonymousSections @collapsableSections={{@collapsableSections}} />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSections;
