import { template as template_299dea8144f54861840dcb6d8f7688e6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_299dea8144f54861840dcb6d8f7688e6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
