import { template as template_07d3ddec427c45aba3de9f960480b6a6 } from "@ember/template-compiler";
const FKText = template_07d3ddec427c45aba3de9f960480b6a6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
